/* eslint-disable react/jsx-props-no-spreading */
/** @jsx jsx */
import { jsx } from 'theme-ui';

const ContentContainerGrid = (props) => (
  <div
    {...props}
    sx={{
      display: 'grid',
      gridTemplateRows: '[header] auto [content] auto [footer] auto',
      gridTemplateColumns: [
        '1em [content-start] repeat(3, 1fr) [halfway] repeat(3, 1fr) [content-end] 1em',
        '1em 1fr [content-start] repeat(2, 1fr) [halfway] repeat(2, 1fr) [content-end] 1fr 1em',
        '1em repeat(3, 1fr) [content-start] repeat(3, 1fr) [halfway] repeat(3, 1fr) [content-end] repeat(3, 1fr) 1em',
      ],
      gridColumnGap: [null, 3],
    }}
  />
);

export default ContentContainerGrid;
