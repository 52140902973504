/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
import PropTypes from 'prop-types';
import Img from './image';
import MarkdownRender from './markdown-render';
import ContentContainerGrid from './content-container-grid';
import Article from './article';

const GenericContent = ({
  title, subtitle, image, aside, body, children, ...other
}) => (
  <ContentContainerGrid {...other}>
    {image && (
      <Img
        src={image}
        alt={title}
        sx={{
          gridColumn: 'content-start / content-end',
          mb: 3,
        }}
      />
    )}
    <header sx={{ gridColumn: 'content-start / content-end' }}>
      <Styled.h1>{title}</Styled.h1>
      {subtitle && <Styled.h2 sx={{ color: 'muted', mt: -3, mb: 3 }}>{subtitle}</Styled.h2>}
    </header>
    <Article>
      {body && <MarkdownRender md={body} />}
      {children}
    </Article>
    {aside && (
      <aside
        sx={{
          gridColumn: ['content-start / content-end', '2', '3 / span 2'],
          gridRow: [null, 'content'],
        }}
      >
        {aside.image && <Img src={aside.image} alt={aside.imageDesc} />}
      </aside>
    )}
  </ContentContainerGrid>
);

GenericContent.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  image: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  aside: PropTypes.shape({
    image: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    imageDesc: PropTypes.string,
  }),
  body: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

GenericContent.defaultProps = {
  subtitle: null,
  image: null,
  aside: null,
  children: null,
  body: null,
};

export default GenericContent;
