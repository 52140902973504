import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
/** @jsx jsx */
import { jsx } from 'theme-ui';
import Layout from '../components/layout';
import Metadata from '../components/metadata';
import GenericContent from '../components/generic-content';

const ApproachPage = ({ data }) => {
  const { pages } = data;
  const { frontmatter: metadata } = pages.edges.length > 0 ? pages.edges[0].node : {};

  return (
    <Layout>
      <Metadata pageData={metadata} />
      {pages.edges.map((page) => {
        const {
          node: {
            frontmatter: {
              title, subtitle, image, aside,
            },
            body,
          },
        } = page;
        return (
          <GenericContent
            key={title}
            title={title}
            subtitle={subtitle}
            image={image}
            aside={aside}
            body={body}
            sx={{
              pb: 4,
            }}
          />
        );
      })}
    </Layout>
  );
};

ApproachPage.propTypes = {
  data: PropTypes.shape({
    pages: PropTypes.shape({
      edges: PropTypes.array.isRequired,
    }).isRequired,
  }).isRequired,
};

export default ApproachPage;

export const pageQuery = graphql`
  query {
    pages: allMdx(
      filter: {fields: {slug: {glob: "/approach/*"}}},
      sort: {fields: frontmatter___weight}
    ) {
      edges {
        node {
          frontmatter {
            title
            subtitle
            meta_description
            aside {
              image {
                publicURL
              }
              imageDesc
            }
          }
          body
        }
      }
    }
  }
`;
