/* eslint-disable react/jsx-props-no-spreading */
/** @jsx jsx */
import { jsx } from 'theme-ui';

const ArticleContentWidth = (props) => (
  <article
    {...props}
    sx={{
      gridColumn: 'content-start/content-end',
    }}
  />
);

export default ArticleContentWidth;
